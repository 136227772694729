@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playball&display=swap');

/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.5;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  /* font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif; */
  /* font-family: helvetica,'arial black',arial,sans-serif; */
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.045em;
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  font-weight: 500;
  /*font-weight: 500;  ※游明朝の時は400*/
  color: #333;
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
	color: #b50824;
  text-decoration: 　underline;
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Main {
  min-height: 500px;
  _height: 500px;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

#Wall{
	display: none;
}

/*####################　ヘッダ　####################*/
#Header{
	position: relative;
	background-color: #fff;
	h1{
		margin: 0 auto;
		text-align: center;
		letter-spacing: .06em;
		a{
			text-decoration: none;
			color: #000;
			b{
				display: block;
				font-family: 'Work Sans', sans-serif;
				font-weight: 600;
			}
		}
	}
  #ContBoxHeader p{
	  position: absolute;
	  a{
		  display: block;
		  background-image: url(../img/header/logo.png);
		  background-repeat: no-repeat;
		  background-position: center center;
		  text-indent: -9999px;
	  }
  }
}

/*####################　グローバルナビ　####################*/
#MenuButton {
	cursor: pointer;
	position: fixed;
	z-index: 10;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../img/header/icon_01.png);
	background-size: 20px auto;
	background-color: #f0f0f0;
	b{
		visibility: hidden;
		position: relative;
		top: 17px;
		left: -69px;
		letter-spacing: 0.18em;
		font-family: 'Work Sans', sans-serif;
		font-variant: small-caps;
		font-size: 14px;
		font-weight: 600;
		color: #888;
	}
}

.active #MenuButton {
	border: 1px solid #ddd;
	background-image: url(../img/header/icon_02.png);
	background-size: 16px auto;
	background-color: #fff;
	b{
		visibility: visible;
	}
}

.modaal-noscroll #MenuButton {
	display: none;
}

#HeaderMenu_sp {
	display: none;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 8;
	width: 100%;
	height: 100%;
	background-color: #fff;
	-webkit-overflow-scrolling: touch;
	&::after{
		content:"";
		display:block;
		clear:both;
	}
	.imageBox,.menuBox{
		height: 100%;
	}
	.imageBox{
		float: left;
		background-repeat: no-repeat;
		background-image: url(../img/header/00_menu.jpg);
	}
	.menuBox{
		float: right;
		ul{
			display: table-cell;
			vertical-align: middle;
			li{
				text-align: left;
				letter-spacing: 1px;
				font-size: 16px;
				&:last-of-type{
					margin-bottom: 0;
				}
				a{
					position: relative;
					padding-left: 35px;
					text-decoration: none;
					color: #000;
					&::before{
						content: "";
						position: absolute;
						left: 0;
						display: block;
						width: 20px;
						height: 1px;
						background-color: #000;
					}
				}
			}
		}
	}
}
.active #HeaderMenu_sp {
	display: block;
}

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
pc,sp共部分
-------------------------------------*/
#TopicPath {
	position: relative;
  text-align: left;
  ol {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    list-style: none;
    line-height: 1;
    font-family: helvetica,'arial black',arial,sans-serif;
    color: #888;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      background: url(../img/contents/arrow_topicpath.png) no-repeat left center;
      background-size: 6px auto;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: #888;
      &.home {
        padding-left: 0;
        background: none;
      }
      a {
		text-decoration: underline;
      }
    }
  }
}

/*　メイン
-------------------------------------*/
.textTypePlayBall{
	font-family: 'Playball', cursive;
	font-weight: 400;
	b{
		font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
		font-weight: 500;
	}
}

#MainImg{
	color: #fff;
	.textTypePlayBall{
		color: #fff;
	}
	.contentBox01{
		p{
			line-height: 32px;
			letter-spacing: 5px;
			font-size: 16px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&::after{
			z-index: 0;
			display: block;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
	h3{
		letter-spacing: 11px;
		span{
			display: inline-block;
			padding: 8px 10px 0;
			height: 30px;
			line-height: 1;
			text-align: center;
			letter-spacing: .06em;
			font-size: 14px;
		}
	}
}

#Main{
	.list_MoreBtn {
		position: relative;
		background-color: #095eb5;
		font-family: 'Work Sans', sans-serif;
		font-weight: 600;
		text-align: center;
		line-height: 70px;
		letter-spacing: .24em;
		color: #fff;
		&:before {
			position: absolute;
			content: "";
			top:0;
			left: 50%;
			width: 1px;
			height: 15px;
			background-color: #fff;
		}
	}
	.pageMove {
		border-top-style: solid;
		border-top-width: 1px;
		ul {
			text-align: center;
			li {
				display: inline-block;
				font-size: 14px;
				&.linkBtn {
					a {
						position: relative;
						display: block;
						height: 40px;
						font-family: 'Work Sans', sans-serif;
						font-weight: 600;
						color: #fff;
						text-decoration: none;
						line-height: 40px;
						letter-spacing: .24em;
						&:after{
							content: "";
							position: absolute;
							top: 50%;
							width: 20px;
							height: 1px;
							background-color: #fff;
						}
					}
				}
				&.prev {
					a {
						&:after {
							right: 0;
						}
					}
				}
				&.next {
					a {
						&:after {
							left: 0;
						}
					}
				}
			}
		}
	}
	dd.detaBox,
	p.detaBox {
		b {
			display: inline-block;
			height: 26px;
			padding: 0 10px;
			line-height: 26px;
			vertical-align: middle;
			font-size: 12px;
			color: #fff;
			letter-spacing: 0.1em;
		}
		span {
			display: inline-block;
			height: 26px;
			padding: 0 10px;
			line-height: 26px;
			vertical-align: middle;
			font-family: 'Work Sans', sans-serif;
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.14em;
		}
	}
	p.imageBox {
		margin-bottom: 0;
		img {
			width: 100%;
			height: auto;
		}
	}
}

#BlogNavi {
	h2 {
		display: none;
	}
	ul {
		li {
			a {
				font-size: 16px;
				text-decoration: none;
				color: #000;
				&:hover {
					opacity: 0.6;
					transition: .4s;
				}
			}
			&.current-cat {
				position: relative;
				&:after {
					content: "";
					display: block;
				}
			}
		}
	}
}

.pageEntry {
  	#Container {
  		#Main {
  			h3 {
  				margin-top: 23px;
				line-height: 40px;
  			font-size: 28px;
  			letter-spacing: 0.1em;
  			}
  		}
  	}
}

/*####################　フッタ　####################*/
#Footer{
	background-color: #000;
	p{
		a{
			display: block;
			margin: 0 auto;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url(../img/footer/logo.png);
			text-indent: -9999px;
		}
	}
	#CopyRight{
		line-height: 1;
		text-align: center;
		h2{
			display: none;
		}
		small, address{
			display: inline;
			font-family: 'Work Sans', sans-serif;
			font-weight: 600;
			color: #888;
		}
	}
}

/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  body {
    min-width: 1000px;
    font-size: 15px;
  }
  p {
    margin: 0 0 15px;
    line-height: 15px;
    font-size: 15px;
  }
  a {
    &[href^="tel"] {
      text-decoration: none;
      &:hover {
        cursor: text;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
	#Header, #GlobalNav, #Container {
		min-width: 1000px;
	}
	.innerBasic{
		width:1000px;
		margin:0 auto;
	}
	#Container {
		background-repeat: repeat;
		background-position: center top;
		background-image: url(../img/contents/wall_01.jpg);
		background-color: #fff;
	}
	#Footer {
		min-width: 1000px;
	}
  /*####################　ヘッダ　####################*/
	#Header{
		height: 160px;
		.innerBasic{
			padding-top: 38px;
			width: auto;
			height: 160px;
		}
		h1{
			margin: 0 auto;
			width: 575px;
			line-height: 25px;
			/*letter-spacing: 0.4em;*/
			a{
				font-size: 20px;
				b{
					margin-bottom: 5px;
					font-size: 16px;
					/*letter-spacing: 0.12em;*/
					span{
						font-size: 20px;
					}
				}
			}
		}
	  #ContBoxHeader p{
		  top: 50%;
		  left: 30px;
		  margin-top: -15px;
		  a{
			  width: 205px;
			  height: 31px;
			  background-size: 205px auto;
		  }
		  &:hover{
			  opacity: .6;
			  transition: .4s;
		  }

	  }
  }
  /*####################　グローバルナビ　####################*/
	#MenuButton {
		top: 50px;
		right: 30px;
		&:hover{
			opacity: .6;
			transition: .4s;
		}
	}

	#HeaderMenu_sp {
		.imageBox,.menuBox{
			width: 50%;
		}
		.imageBox{
			background-position: left center;
			background-size: cover;
		}
		.menuBox{
			ul{
				height: 100vh !important;
				padding-left: 90px;
				li{
					margin-bottom: 36px;
					a{
						&::before{
							top: 50%;
						}
						&:hover{
							opacity: .6;
							transition: .4s;
						}
					}
				}
			}
		}
	}

  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    margin: 0 auto;
    margin-bottom: 87px;
    padding-top: 15px;
    width: 1000px;
    ol li {
    	margin-right: 10px;
      padding-left: 14px;
      a:hover {
		text-decoration: none;
		opacity: 0.6;
		transition: .4s;
      }
    }
  }
  /*　メイン
  -------------------------------------*/
	#MainImg{
		padding-top: 38px;
		.textTypePlayBall{
			margin-bottom: 12px;
			letter-spacing: .05em;
			font-size: 60px;
		}
		.contentBox01{
			position: relative;
			z-index: 2;
			padding-left: 58px;
			display: table-cell;
			vertical-align: middle;
			h3{
				margin-bottom: 35px;
				text-align: left;
			}
			p{
				margin-bottom: 16px;
				text-align: left;
			}
			&::before,&::after{
				content: "";
				position: absolute;
				box-sizing: border-box;
			}
			&::before{
				top: 0;
				left: 0;
				z-index: 1;
				border: 4px solid #fff;
			}
			&::after{
				top: 60px;
				right: -470px;
				z-index: 0;
			}
		}
		h3{
			margin-top: 21px;
			margin-bottom: 3px;
			text-align: center;
			line-height: 60px;
			font-size: 32px;
  			span{
				position: relative;
				top: -6px;
				margin-right: 15px;
  			}
		}
	}
	body:not(#Page) #MainImg{
		height: 635px;
		.contentBox01{
			width: 530px;
			height: 371px;
			&::before,&::after{
				width: 530px;
				height: 371px;
			}
		}
	}
  	body:not(.pageIndex) #MainImg {
		height: 265px !important;
  		h2 {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				bottom: -15px;
				width: 100px !important;
				height: 2px !important;
				background-color: #fff;
			}
		}
		h3 {
			margin-top: 30px;
		}
	  }

	#Main{
		.list_MoreBtn {
			margin: 0 auto;
			width: 500px;
			height: 70px;
			font-size: 14px;
			line-height: 70px;
			span {
				font-size: 16px;
			}
			&:hover {
				opacity: 0.6;
				transition: .4s;
			}
		}
		.pageMove {
			padding-top:60px;
			ul {
				position: relative;
				width: 750px;
				margin: 0 auto;
				li {
					display: block;
					&.prev {
						position: absolute;
						top:0;
						left:0;
					}
					&.top {
						position: absolute;
						left:0;
						right: 0;
						top:0;
						bottom: 0;
						margin: auto;
						line-height: 40px;
					}
					&.next {
						position: absolute;
						top:0;
						right: 0;
					}
					&.linkBtn {
						a {
							width: 125px;
							font-size: 10px;
							span {
								font-size: 12px;
							}
							&:hover {
								opacity: 0.6;
								transition: .4s;
							}
						}
					}
				}
			}
		}
	}
	#BlogNavi {
		margin-bottom: 90px;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		ul {
			li {
				display: inline-block;
				margin-right: 45px;
				padding-top: 16px;
				padding-bottom: 30px;
				letter-spacing: 0.13em;
				&.current-cat {
					padding-top: 0;
					a {
						padding-top: 17px;
						background-position: top center;
						background-size: 6px auto;
						background-repeat: no-repeat;
					}
					&:after {
						position: absolute;
						bottom: 0;
						left: 50%;
						width: 1px;
						height: 36px;
					}
				}
			}
		}
	}

	.pageEntry {
		#Container {
			#Main {
				#ContBox01 {
					margin: 0 auto;
					width: 750px;
				}
				.entryBox {
					margin-top: 50px;
					padding-bottom: 90px;
				}
			}
		}
	  }

/*####################　フッタ　####################*/
	#Footer{
		.innerBasic{
			padding-top: 60px;
			padding-bottom: 58px;
		}
		p{
			margin-bottom: 25px;
			a{
				width: 342px;
				height: 52px;
				background-size: 342px auto;
			}
		}
		#CopyRight{
			small, address{
				font-size: 10px;
				span{
				font-size: 12px;
				}
			}
		}
	}
}

@media print,screen and (max-width: 1000px) {
	#Header{
	  #ContBoxHeader p{
		  left: 5px;
	  }
  }
}

@media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
	#Header{
		h1{
			width: 600px;
		}
	}
	#Main{
		dd.detaBox,
		p.detaBox {
			b, span {
				padding-top: 2px;
			}
		}
	}
}

/*#################### SP ####################*/
@media screen and (max-width: 767px) {
  body {
    font-size: 15px;
  }
  p {
    margin: 0 0 15px;
    line-height: 15px;
    font-size: 15px;
  }
  .pc_display,.disp_pc {
    display: none;
  }
  .sp_display,.disp_sp {
    display: block;
  }
  .sp_display_inline {
    display: inline;
  }
/*####################　グリッド　####################*/
	.grid-line {
		position: fixed;
		top: 0;
		right: auto;
		bottom: 0;
		left: 50%;
		display: -ms-grid;
		display: grid;
		width: 137vw;
		height: 100%;
		pointer-events: none;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		-ms-grid-columns: (calc(100% / 6))[6];
		grid-template-columns: repeat(6, calc(100% / 6));
	}

	.grid-line__cassette {
		border-right: 1px solid #f5f5f5;
	}

/*####################　枠設定　####################*/
  .innerBasic {
    margin: 0 auto;
	padding-right: 15px;
	padding-left: 15px;
  }

/*####################　ヘッダ　####################*/
	#Header{
		height: 200px;
		.innerBasic{
			padding-top: 80px;
			width: auto;
			height: 200px;
		}
		h1{
			a{
				/*letter-spacing: 6px;*/
				font-size: 20px;
				b{
					/*letter-spacing: 1px;*/
					font-size: 12px;
					span{
						font-size: 14px;
					}
					em{
						display: block;
						line-height: 21px;
						font-size: 16px;
						span{
							font-size: 20px;
						}
					}
				}
			}
		}
	  #ContBoxHeader p{
		  top: 30px;
		  left: 15px;
		  a{
			  width: 128px;
			  height: 20px;
			  background-size: 128px auto;
		  }
	  }
  }
  /*####################　グローバルナビ　####################*/
	#MenuButton {
		top: 10px;
		right: 15px;
	}

	#HeaderMenu_sp {
		height: 100vh;
		.imageBox{
			width: 25%;
			background-position: right center;
			background-size: auto 100%;
			height: 100vh;
		}
		.menuBox{
			width: 75%;
			ul{
				padding-right: 15px;
				padding-left: 30px;
				height: 100vh;
				li{
					margin-bottom: 36px;
					a{
						font-feature-settings: normal;
						-moz-font-feature-settings: normal;
						-webkit-font-feature-settings: normal;
						&::before{
							top: 10px;
						}
					}
				}
			}
		}
	}

  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    margin-bottom: 60px;
    padding: 9px 15px 0;
    ol li {
		margin-right: 11px;
		padding-left: 17px;
    }
  }

/*　メイン
  -------------------------------------*/
	#MainImg{
		position: relative;
		padding-top: 31px;
		.textTypePlayBall{
			position: relative;
			margin-bottom: 35px;
			padding-bottom: 30px;
			letter-spacing: .05em;
			font-size: 40px;
			&::after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 50%;
				display: block;
				margin-left: -50px;
				width: 100px;
				height: 2px;
				background-color: #fff;
			}
		}
		.contentBox01{
			h3{
				margin-bottom: 32px;
			}
			p{
				margin-bottom: 16px;
				letter-spacing: 5px;
				text-align: center;
			}
			&::after{
				content: "";
				margin-top: 35px;
				margin-right: -15px;
				margin-left: -15px;
			}
		}
		h3{
			text-align: center;
			line-height: 45px;
			letter-spacing: 9px;
			font-size: 26px;
			span{
				margin-bottom: 15px;
			}
		}
		h2 + h3{
			margin-top: -2px;
		}
	}
	body:not(#Page) #MainImg {
		.contentBox01{
			&::after{
				padding-top: 70.8%;
			}
		}
	}
	body:not(.pageIndex) #MainImg {
		padding-bottom: 34px;
		.contentBox01 {
			h3 {
				margin-bottom: 25px;
			}
		}
	}

	#Main{
		.list_MoreBtn {
			height: 60px;
			font-size: 14px;
			line-height: 60px;
			span {
				font-size: 16px;
			}
		}
		.pageMove {
			padding-top:45px;
			ul {
				position: relative;
				li {
					&.top {
						padding-top: 10px;
					}
					&.linkBtn {
						a {
							width: 105px;
							font-size: 10px;
							span {
								font-size: 12px;
							}
						}
						&.prev {
							position: absolute;
							left: 0;
						}
						&.next {
							position: absolute;
							right: 0;
						}
					}
				}
			}
		}
	}

	#BlogNavi {
		position: relative;
		ul {
			position: relative;
			margin-bottom: 60px;
			&::after{
				content:"";
				display:block;
				clear:both;
			}
			&::before{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1;
				display: block;
				width: 100%;
				height: 1px;
				background-color: #fff;
			}
			li {
				float: left;
				width: 50%;
				border-width: 1px;
				border-style: solid;
				border-top: none;
				a{
					display: block;
					padding: 13px 10px 13px 26px;
				}
				&.current-cat {
					&:after {
						position: absolute;
						top: 22px;
						left: 14px;
						border-radius: 50%;
						width: 6px;
						height: 6px;
					}
				}
				&:nth-child(even) {
					border-left:none;
					border-right: none;
				}
			}
		}
	}
	.pageEntry {
		#Container {
			#Main {
				#ContBox01 {
					margin-top: -30px;
				}
				.entryBox {
					margin-top: 15px;
					padding-bottom: 60px;
				}
			}
		}
	}

/*####################　フッタ　####################*/
	#Footer{
		position: relative;
		.innerBasic{
			padding-top: 42px;
			padding-bottom: 39px;
		}
		p{
			margin-bottom: 12px;
			a{
				width: 228px;
				height: 35px;
				background-size: 228px auto;
			}
		}
		#CopyRight{
			line-height: 14px;
			letter-spacing: 0;
			small, address{
				font-size: 8px;
				span{
				font-size: 10px;
				}
			}
		}
	}
}


/*end*/